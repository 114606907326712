<template>
  <form @submit.prevent="onSubmit">
    <div class="mb-3 text-center">
      <img
        src="../../assets/logo_login.jpg"
        alt="logo"
        class="img-fluid"
      >
    </div>
    <div class="mb-3">
      <label for="email">E-Mail</label>
      <input
        type="email"
        name="email"
        class="form-control form-control-sm"
        v-model="email"
        required
      >
    </div>
    <div class="mb-3">
      <label for="password">Passwort</label>
      <input
        type="password"
        name="password"
        class="form-control form-control-sm"
        v-model="password"
        required
      >
    </div>
    <div class="mb-3">
      <button
        type="submit"
        class="btn btn-block btn-dark btn-sm w-100"
        :disabled="requesting"
      >Anmelden</button>
    </div>
    <div class="mb-3">
      <div
        class="alert alert-danger text-center"
        v-if="errMsg"
      >{{errMsg}}</div>
    </div>
  </form>
</template>

<script>
export default {
  name: "login-form",
  props: {
    errMsg: {
      type: String,
      required: true
    },
    requesting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    onSubmit() {
      this.$emit("login", {
        email: this.email,
        password: this.password
      });
    }
  }
};
</script>
