<template>
  <div class="container vh-100">
    <div class="row h-75 justify-content-center align-items-center">
      <div class="col col-xl-4">
        <login-form
          @login="onLogin"
          :requesting="requesting"
          :errMsg="errMsg"
        ></login-form>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/login/LoginForm';
import { auth } from '../../firebase';

export default {
  name: 'login',
  components: {
    LoginForm
  },
  data() {
    return {
      requesting: false,
      errMsg: ''
    };
  },
  methods: {
    async onLogin({ email, password }) {
      this.requesting = true;
      this.errMsg = '';

      try {
        await auth.signInWithEmailAndPassword(email, password);

        this.$router.push({ name: 'calendar' });
      } catch (err) {
        this.errMsg = err.message;
        this.requesting = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vh-100 {
  height: 100vh;
}
</style>
